import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { api_base_url } from "config";

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${api_base_url}/api/`,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("access_token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  reducerPath: "adminApi",
  tagTypes: [
    "CrawledCupons",
  ],
  endpoints: (build) => ({
    getCrawledCupons: build.query({
      query: (params) => {
        const queryString = new URLSearchParams(params).toString();
        return `crawled-coupons/?${queryString}`;
      },
      providesTags: ['CrawledCupons'],
    }),
  }),
});

export const {
  useGetCrawledCuponsQuery,

} = api;
