import AppShortcutIcon from "@mui/icons-material/AppShortcut";
import CategoryIcon from "@mui/icons-material/Category";
import DiscountIcon from "@mui/icons-material/Discount";
import LeakAddIcon from "@mui/icons-material/LeakAdd";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import PestControlIcon from "@mui/icons-material/PestControl";
import StoreIcon from "@mui/icons-material/Store";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const navItems = [
  {
    text: "Cupons capturados",
    icon: <PestControlIcon />,
    to: "/crawled-coupon",
    subItems: [
      {
        text: "TODOS",
        to: "/crawled-coupon",
      },
      {
        text: "Novos",
        to: "/crawled-coupon/new",
      },
      {
        text: "Removidos",
        to: "/crawled-coupon/removed",
      },
    ],
  },
  {
    text: "Lojas",
    icon: <StoreIcon />,
    to: "/stores",
  },
  {
    text: "Cupons",
    icon: <DiscountIcon />,
    to: "/coupons",
  },
  {
    text: "Categorias",
    icon: <CategoryIcon />,
    to: "/categories",
  },
  {
    text: "Especiais",
    icon: <AppShortcutIcon />,
    to: "/specials",
  },
  {
    text: "Redes",
    icon: <LeakAddIcon />,
    to: "/network",
  },
  {
    text: "URLs Quebradas",
    icon: <LinkOffIcon />,
    to: "/broken-urls",
    to: "/broken-urls",
  },
];

const Sidebar = ({
  isSidebarOpen,
  setIsSidebarOpen,
  drawerWidth,
  isMobile,
}) => {
  const [active, setActive] = useState("");
  const [openItems, setOpenItems] = useState({});
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    setActive(pathname);
  }, [pathname]);

  const handleClick = (to, index, hasSubItems) => {
    if (hasSubItems) {
      setOpenItems((prevOpenItems) => ({
        ...prevOpenItems,
        [index]: !prevOpenItems[index],
      }));
    } else if (to) {
      setActive(to);
      navigate(to);
    }
  };

  return (
    <Drawer
      open={isSidebarOpen}
      onClose={() => setIsSidebarOpen(false)}
      variant="persistent"
      sx={{
        width: isMobile ? "auto" : drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          paddingTop: "64px", // 64px from theme.mixins.toolbar.minHeight
        },
      }}
    >
      <List>
        {navItems.map(({ text, icon, to, subItems }, index) => (
          <React.Fragment key={text}>
            <ListItemButton
              onClick={() => handleClick(to, index, !!subItems)}
              selected={active.startsWith(to)}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={text} />
              {subItems ? (openItems[index] ? <ExpandLess /> : <ExpandMore />) : null}
            </ListItemButton>
            {subItems && (
              <Collapse in={openItems[index]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {subItems.map((subItem) => (
                    <ListItemButton
                      key={subItem.to}
                      sx={{ pl: 4 }}
                      onClick={() => handleClick(subItem.to)}
                      selected={active === subItem.to}
                    >
                      <ListItemText primary={subItem.text} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>

    </Drawer>
  );
};

export default Sidebar;
