import React from "react";
import { AppBar, Toolbar, Button, Select, MenuItem } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const AppBarWithFilters = ({
  handleExport,
  handleCancel,
  handleRunCommand,
  selectedSpider,
  spiderOptions,
  handleSpiderChange,
  isRunning,
  showRunCommand = true,
}) => {
  return (
    <AppBar position="static" color="default">
      <Toolbar>
        <Button
          variant="contained"
          color="primary"
          startIcon={<FileDownloadIcon />}
          onClick={handleExport}
          sx={{ ml: 2 }}
        >
          Exportar CSV
        </Button>

        {showRunCommand && (
          <>
            <Button
              variant="contained"
              color="primary"
              onClick={handleRunCommand}
              disabled={isRunning}
              sx={{ ml: 2 }}
            >
              {isRunning ? "Carregando..." : "Atualizar Crawlers"}
            </Button>

            {isRunning && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
                sx={{ ml: 2 }}
              >
                Cancelar
              </Button>
            )}
          </>
        )}

        <Select
          value={selectedSpider}
          onChange={handleSpiderChange}
          displayEmpty
          sx={{
            ml: 4,
            color: "inherit",
            bgcolor: "background.paper",
            borderRadius: 1,
            height: "35px",
            "& .MuiSvgIcon-root": {
              color: "inherit",
            },
            fontSize: "0.875rem",
          }}
        >
          <MenuItem value="">
            <em>Selecione o Spider</em>
          </MenuItem>
          {spiderOptions.map((spider) => (
            <MenuItem key={spider} value={spider}>
              {spider}
            </MenuItem>
          ))}
        </Select>
      </Toolbar>
    </AppBar>
  );
};


export default AppBarWithFilters;
