import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { api_base_url } from "config";
import { categories_endpoints } from "./categories";
import { specials_endpoints } from "./specials";
import { slugs_endpoints } from "./slugs";
import { paramsSerializer } from "utils/paramSerializer";
import { networks_endpoints } from "./networks";
import { stores_endpoints } from "./stores";
import { coupon_kind_endpoints } from "./coupon-kind";
import { coupons_endpoints } from "./coupons";

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${api_base_url}/api/`,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem("access_token");
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
    paramsSerializer: paramsSerializer,
  }),
  reducerPath: "adminApi",
  tagTypes: [
    "CrawledCupons",
    "Stores",
    "Categories",
    "Coupons",
    "Specials",
    "Networks",
  ],
  endpoints: (build) => ({
    getCrawledCupons: build.query({
      query: () => ({
        url: "crawled-coupons/",
        method: "GET",
      }),
      providesTags: ["CrawledCupons"],
    }),
    runGetCrawledCoupons: build.mutation({
      query: () => ({
        url: "run-get-crawled-coupons/",
        method: "POST",
      }),
    }),
    cancelGetCrawledCoupons: build.mutation({
      query: () => ({
        url: "cancel-get-crawled-coupons/",
        method: "POST",
      }),
    }),
  }),
})
  .injectEndpoints(categories_endpoints)
  .injectEndpoints(slugs_endpoints)
  .injectEndpoints(specials_endpoints)
  .injectEndpoints(networks_endpoints)
  .injectEndpoints(stores_endpoints)
  .injectEndpoints(coupon_kind_endpoints)
  .injectEndpoints(coupons_endpoints);

export const {
  useCreateCategoryMutation,
  useCreateCouponsMutation,
  useCreateCouponKindMutation,
  useCreateNetworkMutation,
  useCreateSlugsMutation,
  useCreateSpecialMutation,
  useCreateStoreMutation,
  useDeleteCategoryMutation,
  useDeleteCouponMutation,
  useDeleteCouponKindMutation,
  useDeleteNetworkMutation,
  useDeleteSlugsMutation,
  useDeleteSpecialMutation,
  useDeleteStoreMutation,
  useGetCategoriesQuery,
  useGetCategoryQuery,
  useGetCouponsQuery,
  useGetCouponQuery,
  useGetCouponKindsQuery,
  useGetCrawledCuponsQuery,
  useGetNetworkQuery,
  useGetNetworksQuery,
  useGetSlugsQuery,
  useGetSpecialQuery,
  useGetSpecialsQuery,
  useGetStoreQuery,
  useGetStoresQuery,
  useGetTrackedUrlPreviewMutation,
  useGetTrackingMethodsQuery,
  useUpdateCategoryMutation,
  useUpdateCouponMutation,
  useUpdateCouponKindMutation,
  useUpdateNetworkMutation,
  useUpdateSlugMutation,
  useUpdateSpecialMutation,
  useUpdateStoreMutation,
  useRunGetCrawledCouponsMutation,
  useCancelGetCrawledCouponsMutation,
} = api;
