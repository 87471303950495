import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, useTheme } from '@mui/material';
import { useGetCrawledCuponsQuery, useRunGetCrawledCouponsMutation, useCancelGetCrawledCouponsMutation } from 'state/api';
import Header from 'components/Header';
import DataGridCustomTableClientSide from 'scenes/crawled_cupons/components/DataGridCustomTableClientSide';
import getColumns from './columns';
import AppBarWithFilters from '../components/AppBar';
import useFilteredData from '../components/FilteredData';
import { exportToCSV } from '../hooks/exportToCSV';

const CrawledCouponsList = ({ spider }) => {
  const [page, setPage] = useState(2);
  const [pageSize, setPageSize] = useState(20);
  const [selectedSpider, setSelectedSpider] = useState("");
  const { data = [], isLoading } = useGetCrawledCuponsQuery({}, { skip: false });
  const [runGetCrawledCoupons] = useRunGetCrawledCouponsMutation();
  const [cancelGetCrawledCoupons] = useCancelGetCrawledCouponsMutation();
  const [spiderOptions, setSpiderOptions] = useState([]);
  const [isFilterOn, setIsFilterOn] = useState(true);
  const [filters, setFilters] = useState({ store_name: "", is_on_tr: "", is_new: "" });
  const [isRunning, setIsRunning] = useState(false);

  const handleFiltersChange = useCallback((event) => {
    const { name, value } = event.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  }, []);

  useEffect(() => {
    if (Array.isArray(data)) {
      const uniqueSpiders = Array.from(new Set(data.map(item => item.spider))).sort();
      setSpiderOptions(uniqueSpiders);
    }
  }, [data]);

  const filteredData = useFilteredData(data, selectedSpider, filters);

  const handleSpiderChange = (event) => {
    setSelectedSpider(event.target.value);
  };

  const handleRunCommand = async () => {
    setIsRunning(true);
    try {
      const response = await runGetCrawledCoupons().unwrap();
      console.log('Comando executado com sucesso:', response);
      alert('Comando executado com sucesso!');
    } catch (error) {
      console.error('Erro ao executar o comando:', error);
      alert('Erro ao executar o comando.');
    } finally {
      setIsRunning(false);
    }
  };

  const handleCancel = async () => {
    setIsRunning(false);
    try {
      const response = await cancelGetCrawledCoupons().unwrap();
      console.log("Cancelamento solicitado com sucesso:", response);
      alert("Cancelamento solicitado com sucesso!");
    } catch (error) {
      console.error("Erro ao solicitar cancelamento:", error);
      alert("Erro ao solicitar cancelamento.");
    }
  };

  const handleExport = () => {
    exportToCSV(columns, filteredData);
  };

  const columns = getColumns(isFilterOn, filters, handleFiltersChange, spiderOptions, selectedSpider, handleSpiderChange);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "1rem 1rem",
        height: "100vh",
      }}
    >
      <Header title="Todos Cupons do Crawler" subtitle="Tabela dos Cupons" />
      <AppBarWithFilters
        selectedSpider={selectedSpider}
        spiderOptions={spiderOptions}
        handleSpiderChange={handleSpiderChange}
        handleExport={handleExport}
        handleRunCommand={handleRunCommand}
        isRunning={isRunning} // Passa o estado para o AppBarWithFilters
        handleCancel={handleCancel}
      />
      <Box sx={{ flexGrow: 1 }}>
        <DataGridCustomTableClientSide
          data={filteredData}
          isLoading={isLoading}
          columns={columns}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          rowCount={filteredData.length}
        />
      </Box>
    </Box>
  );
};

export default CrawledCouponsList;
