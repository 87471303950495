import React, { useMemo } from "react";
import {
  useDeleteNetworkMutation,
  useGetNetworksQuery,
  useUpdateNetworkMutation,
} from "state/api";
import getColumns from "./columns";

import DataGridEditableRow from "components/DataGridEditableRow";
import { useGetTrackingMethodsQuery } from "state/api";
import Loader from "components/Loader";

export const Networks = () => {
  const { data: trackingMethods, isLoading } = useGetTrackingMethodsQuery();

  if (isLoading) {
    return <Loader />;
  }
  return (
    <DataGridEditableRow
      useGet={useGetNetworksQuery}
      useUpdate={useUpdateNetworkMutation}
      useDelete={useDeleteNetworkMutation}
      getRowHeight={() => "auto"}
      columns={getColumns(trackingMethods)}
      baseUrl="/networks"
    />
  );
};

export default React.memo(Networks);
