import StoreIcon from '@mui/icons-material/Store';
import LinkIcon from '@mui/icons-material/Link';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import { TextField } from "@mui/material";
import { format, parseISO} from 'date-fns';

const getColumns = (isFilterOn, filters, handleFiltersChange) => [
  {
    field: "store_name",
    headerName: "Nome da Loja",
    width: 200,
    renderHeader: () => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          gap: "1px",
          height: "80px",
          padding: "8px",
        }}
      >
        <div style={{ fontWeight: "bold" }}>Nome da Loja</div>
        {isFilterOn && (
          <TextField
            name="store_name"
            value={filters.store_name}
            onChange={handleFiltersChange}
            placeholder="Filtrar"
            variant="outlined"
            size="small"
            fullWidth
          />
        )}
      </div>
    ),
  },
  {
    field: "code",
    headerName: "Código",
    width: 200,
    renderHeader: () => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          gap: "1px",
          height: "80px",
          padding: "8px",
        }}
      >
        <div style={{ fontWeight: "bold" }}>Código</div>
        {isFilterOn && (
          <TextField
            name="code"
            value={filters.code}
            onChange={handleFiltersChange}
            placeholder="Filtrar"
            variant="outlined"
            size="small"
            fullWidth
          />
        )}
      </div>
    ),
    renderCell: (params) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <span>{params.value}</span>
        {params.value && (
          <IconButton
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              navigator.clipboard.writeText(params.value);
            }}
            title="Copiar código"
          >
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        )}
      </div>
    ),
  },
  {
    field: "store_slug",
    headerName: "SLUG",
    width: 150,
    renderHeader: () => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "1px",
          height: "80px",
          padding: "8px",
        }}
      >
        <div style={{ fontWeight: "bold" }}>SLUG</div>
        {isFilterOn && (
          <TextField
            name="store_slug"
            value={filters.store_slug}
            onChange={handleFiltersChange}
            placeholder="Filtrar"
            variant="outlined"
            size="small"
            fullWidth
          />
        )}
      </div>
    ),
  },
  {
    field: "is_on_tr",
    headerName: "TR?",
    width: 150,
    renderHeader: () => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "1px",
          height: "80px",
          padding: "8px",
        }}
      >
        <div style={{ fontWeight: "bold" }}>TR</div>
        {isFilterOn && (
          <TextField
            name="is_on_tr"
            value={filters.is_on_tr}
            onChange={handleFiltersChange}
            placeholder="Filtrar"
            variant="outlined"
            size="small"
            fullWidth
          />
        )}
      </div>
    ),
  },
  {
    field: "store_url",
    headerName: "URL da Loja",
    minWidth: 150,
    renderHeader: (params) => (
      <strong>{params.colDef.headerName}</strong>
    ),
    renderCell: (params) => {
      return (
        <a href={`${params.value}`} rel="noopener noreferrer" target="_blank">
          <StoreIcon style={{ color: 'grey', fontSize: "32px" }} />
        </a>
      );
    }
  },
  {
    field: "title",
    headerName: "Título",
    flex: 1,
    minWidth: 500,
    renderHeader: () => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "1px",
          height: "80px",
          padding: "8px",
        }}
      >
        <div style={{ fontWeight: "bold" }}>Título</div>
        {isFilterOn && (
          <TextField
            name="title"
            value={filters.title}
            onChange={handleFiltersChange}
            placeholder="Filtrar"
            variant="outlined"
            size="small"
            fullWidth
          />
        )}
      </div>
    ),
    renderCell: (params) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <span>{params.value}</span>
        {params.value && (
          <IconButton
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              navigator.clipboard.writeText(params.value);
            }}
            title="Copiar código"
          >
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        )}
      </div>
    ),
  },
  {
    field: "description",
    headerName: "Descrição",
    minWidth: 700,
    renderHeader: () => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "1px",
          height: "80px",
          padding: "8px",
        }}
      >
        <div style={{ fontWeight: "bold" }}>Descrição</div>
        {isFilterOn && (
          <TextField
            name="description"
            value={filters.description}
            onChange={handleFiltersChange}
            placeholder="Filtrar"
            variant="outlined"
            size="small"
            fullWidth
          />
        )}
      </div>
    ),
    renderCell: (params) => (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <span>{params.value}</span>
        {params.value && (
          <IconButton
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              navigator.clipboard.writeText(params.value);
            }}
            title="Copiar código"
          >
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        )}
      </div>
    ),
  },
  {
    field: "url",
    headerName: "URL",
    flex: 1,
    minWidth: 100,
    renderHeader: (params) => (
      <strong>{params.colDef.headerName}</strong>
    ),
    renderCell: (params) => (
      <a href={`${params.value}`} rel="noopener noreferrer" target="_blank">
        <LinkIcon style={{ color: 'grey', fontSize: "32px" }} />
      </a>
    )
  },
  {
    field: "last_time_seen",
    headerName: "Visto por último",
    flex: 1,
    minWidth: 250,
    renderHeader: () => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "1px",
          height: "80px",
          padding: "8px",
        }}
      >
        <div style={{ fontWeight: "bold" }}>Visto por último</div>
        {isFilterOn && (
          <TextField
            type="date"
            value={filters.last_time_seen || ""}
            onChange={(event) =>
              handleFiltersChange({
                target: { name: "last_time_seen", value: event.target.value },
              })
            }
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        )}
      </div>
    ),
    renderCell: (params) =>
      params.value ? format(parseISO(params.value), "dd/MM/yyyy") : "",
  },
  {
    field: "first_time_seen",
    headerName: "Visto primeira vez",
    minWidth: 250,
    renderHeader: () => (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "1px",
          height: "80px",
          padding: "8px",
        }}
      >
        <div style={{ fontWeight: "bold" }}>Visto primeira vez</div>
        {isFilterOn && (
          <TextField
            type="date"
            value={filters.first_time_seen || ""}
            onChange={(event) =>
              handleFiltersChange({
                target: { name: "first_time_seen", value: event.target.value },
              })
            }
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
          />
        )}
      </div>
    ),
    renderCell: (params) =>
      params.value ? format(parseISO(params.value), "dd/MM/yyyy") : "",
  },


];

export default getColumns;
